import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const Image = ({ style, name }) => {
  const data = useStaticQuery(graphql`
    query {
      Img1: file(
        relativePath: { eq: "gallery/landscaping/13.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Img2: file(
        relativePath: { eq: "gallery/exposed/5.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Img3: file(
        relativePath: { eq: "gallery/plain/52.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Img4: file(relativePath: { eq: "gallery/exposed/31.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Img5: file(relativePath: { eq: "gallery/coloured/16.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Img6: file(relativePath: { eq: "gallery/exposed/6.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return <Img style={style} fluid={data[name].childImageSharp.fluid} />
}

const imgStyles = {
  width: "735px",
  height: "auto"
}
export const SliderGallery = ({ style }) => {
  return (
    <div style={{ margin: "0 -16px", ...style }}>
      <Slider
        infinite={true}
        autoplay={true}
        dots={true}
        arrows={false}
        pauseOnHover={true}
        // variableWidth={true}
        slidesToShow={3}
        // slidesToScroll={1}
        responsive={[
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]}
      >
        <Image style={imgStyles} name={"Img1"} />
        <Image style={imgStyles} name={"Img2"} />
        <Image style={imgStyles} name={"Img3"} />
        <Image style={imgStyles} name={"Img4"} />
        <Image style={imgStyles} name={"Img5"} />
        <Image style={imgStyles} name={"Img6"} />
      </Slider>
    </div>
  )
}
