import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import {
  ButtonLink,
  ButtonPrimary,
  ButtonSecondary,
  Colors,
  ContainerContent,
  ContainerContentFixed,
  ContentAboutUs,
  DisplayReviewList,
  InternalLink,
  InternalLinkTypes,
} from "../components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import { Row, Col, Card, Carousel } from "antd"
import { TitleSection } from "../components/titles"
import { SliderGallery } from "../components/sliders"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allServicesJson {
        nodes {
          id
          name
          cols
          slug
          description
          imagePath {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      allReviewsJson {
        nodes {
          client
          platform
          review
        }
      }
      Bg1: file(relativePath: { eq: "gallery/exposed/30.png" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Bg2: file(relativePath: { eq: "gallery/exposed/29.png" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Bg3: file(relativePath: { eq: "gallery/landscaping/6.png" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Bg4: file(relativePath: { eq: "gallery/plain/22.png" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Bg5: file(relativePath: { eq: "gallery/plain/11.png" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Bg6: file(relativePath: { eq: "gallery/coloured/14.png" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)



  const services = data.allServicesJson.nodes
  const reviews = data.allReviewsJson.nodes.slice(0, 3)

  return (
    <Layout>
      <SEO
        title="Urban Concrete Contractors | Based in Victoria"
        description={
          "Urban Concrete Contractors (UCC VIC) is a Victoria based concrete and landscaping company." +
          "That offers professional, reliable and efficient services in the North and West of Melbourne." +
          " Our experienced team of contractors will take care of all of your urban concrete needs, contact us today for your quote."
        }
      />

        <Carousel
            autoplay={true}
            dotPosition={"top"}
            dots={false}
            effect={"fade"}
        >
            <div>
                <Img
                    style={{ maxHeight: "600px" }}
                    fluid={data["Bg1"].childImageSharp.fluid}
                />
            </div>{" "}
            <div>
                <Img
                    style={{ maxHeight: "600px" }}
                    fluid={data["Bg2"].childImageSharp.fluid}
                />
            </div>{" "}
            <div>
                <Img
                    style={{ maxHeight: "600px" }}
                    fluid={data["Bg3"].childImageSharp.fluid}
                />
            </div>{" "}
            <div>
                <Img
                    style={{ maxHeight: "600px" }}
                    fluid={data["Bg4"].childImageSharp.fluid}
                />
            </div>{" "}
            <div>
                <Img
                    style={{ maxHeight: "600px" }}
                    fluid={data["Bg5"].childImageSharp.fluid}
                />
            </div>{" "}
            <div>
                <Img
                    style={{ maxHeight: "600px" }}
                    fluid={data["Bg6"].childImageSharp.fluid}
                />
            </div>
        </Carousel>
      <ContainerContent
        style={{
          textAlign: "center",
          width: "100%",
          // background: "rgba(255, 255, 255, 0.85)",
          background: Colors.White,
        }}
      >
        <ContainerContentFixed>
          <TitleSection style={{ color: Colors.Blue }}>
            For all your urban concrete needs, contact UCC!
          </TitleSection>
          <h2 style={{ color: Colors.Gray, fontWeight: "400" }}>
            Based in Melbourne, Victoria
          </h2>

          <ButtonPrimary>
            <InternalLink url={InternalLinkTypes.Quote}>Get Quote</InternalLink>
          </ButtonPrimary>
        </ContainerContentFixed>
      </ContainerContent>
      <ContainerContent
        style={{ textAlign: "left", background: Colors.LightGray }}
      >
        <ContainerContentFixed>
          <TitleSection style={{ fontWeight: "bold", textAlign: "center" }}>
            OUR SERVICES
          </TitleSection>
          <Row gutter={[16, 16]}>
            {services.map(s => (
              <Col key={s.id} lg={s.cols} xs={24}>
                <Card cover={<Img fluid={s.imagePath.childImageSharp.fluid} />}>
                  <Card.Meta
                    title={
                      <InternalLink url={s.slug}>
                        <span style={{ color: Colors.Blue }}>{s.name}</span>
                      </InternalLink>
                    }
                    description={s.description}
                  />
                  <br />
                  <ButtonLink style={{ marginLeft: "-16px" }}>
                    <InternalLink url={`/services/${s.id}`}>
                      Learn more <FontAwesomeIcon icon={faAngleDoubleRight} />
                    </InternalLink>{" "}
                  </ButtonLink>
                </Card>
              </Col>
            ))}
          </Row>
        </ContainerContentFixed>
      </ContainerContent>
      <ContainerContent>
        <TitleSection style={{ color: Colors.White, textAlign: "center" }}>
          CLIENT REVIEWS
        </TitleSection>
        <DisplayReviewList reviews={reviews} />
        <br />
        <ButtonSecondary style={{ margin: "auto", display: "block" }}>
          <InternalLink url={InternalLinkTypes.Reviews}>
            See More Reviews
          </InternalLink>
        </ButtonSecondary>
      </ContainerContent>
      <ContainerContent
        style={{ background: Colors.White, textAlign: "center" }}
      >
        <TitleSection>OUR WORK</TitleSection>
        <SliderGallery />
        <br />
        <br />
        <ButtonPrimary>
          <InternalLink url={InternalLinkTypes.Gallery}>
            See Our Work
          </InternalLink>
        </ButtonPrimary>
      </ContainerContent>{" "}
      <ContentAboutUs />
    </Layout>
  )
}

export default IndexPage
